import React, { useContext, useEffect, lazy, Suspense } from 'react';
import { Box } from '@mui/material';
import { Routes, Route, useLocation } from 'react-router-dom';
import { Context } from '../context/Context.js';
import ProtectedRoute from './ProtectedRoute';
import Error from '../common/Error.js';
import Loader from '../common/Loader.js';

const Home = lazy(() => import('../pages/Home/Home'));
const Membership = lazy(() => import('../pages/Membership'));
const Maid = lazy(() => import('../pages/Maid/Maid'));
const Checkoutpage = lazy(() => import('../pages/Checkoutpage'));
const MyBookings = lazy(() => import('../pages/Profile/Booking/MyBookings'));
const BookingDetails = lazy(() => import('../pages/Profile/Booking/BookingDetails'));
const Faq = lazy(() => import('../pages/Faq'));
const ProfileSummary = lazy(() => import('../pages/Profile/UserProfile/ProfileSummary'));
const ProfileDetails = lazy(() => import('../pages/Profile/UserProfile/ProfileDetails'));
const Notifications = lazy(() => import('../pages/Profile/UserProfile/Notifications'));
const MobileNumber = lazy(() => import('../pages/Profile/UserProfile/MobileNumber'));
const AboutSevaki = lazy(() => import('../pages/AboutSevaki'));
const ContactUs = lazy(() => import('../pages/ContactUs'));
const Chat = lazy(() => import('../pages/Chat'));
const MiniCart = lazy(() => import('../pages/MiniCart.js'));
const MyAddress = lazy(() => import('../pages/Profile/UserProfile/MyAddress'));
const FavouriteMaids = lazy(() => import('../pages/Profile/UserProfile/FavouriteMaids.js'));
const Store = lazy(() => import('../pages/Store.js'));
const PaymentSuccess = lazy(() => import('../pages/PaymentSuccess.js'));
const TermsAndConditions = lazy(() => import('../pages/TermsAndConditions.js'));
const CancellationPolicy = lazy(() => import('../pages/CancellationPolicy.js'));
const PrivacyPolicy = lazy(() => import("../pages/PrivacyPolicy.js"))

const PageRoute = () => {
  const { openErrorBar } = useContext(Context);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Box>
      {openErrorBar && <Error />}
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/store" element={<Store />} />
          <Route path="/membership" element={<Membership />} />
          <Route path="/maid" element={<Maid />} />
          <Route path="/checkout" element={<Checkoutpage />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/minicart" element={<MiniCart />} />
          <Route path="/Cancellation&RefundPolicy" element={<CancellationPolicy />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
          <Route path="/mybookings" element={<ProtectedRoute><MyBookings /></ProtectedRoute>} />
          <Route path="/bookingdetails/:id" element={<ProtectedRoute><BookingDetails /></ProtectedRoute>} />
          <Route path="/profile" element={<ProtectedRoute><ProfileSummary /></ProtectedRoute>} >
            <Route path="details" element={<ProfileDetails />} />
            <Route path="notifications" element={<Notifications />} />
            <Route path="mobile-change" element={<MobileNumber />} />
            <Route path="myaddress" element={<MyAddress />} />
          </Route>
          <Route path="/PaymentSuccess/:id" element={<ProtectedRoute><PaymentSuccess /></ProtectedRoute>} />
          <Route path="/chat" element={<ProtectedRoute><Chat /></ProtectedRoute>} />
          <Route path="/favouritemaid" element={<ProtectedRoute><FavouriteMaids /></ProtectedRoute>} />
          <Route path="/aboutus" element={<AboutSevaki />} />
        </Routes>
      </Suspense>
    </Box>
  );
};

export default PageRoute;