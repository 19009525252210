import React, { createContext, useEffect, useState } from "react";
import apiUrl from "../config";
import axios from "axios";
import defaultimg from "../Assets/Images/defaultImg.png";

const Context = createContext();

const UserContext = ({ children }) => {
  const [selectService, setSelectService] = useState(null);
  const [selectedAddOn, setSelectedAddOn] = useState()
  const [selectedDate, setSelectedDate] = useState(null);
  const [services, setServices] = useState([]);
  const [serviceId, setServiceId] = useState(null);
  const [error, setError] = useState("null");
  const [serviceFields, setServiceFields] = useState(null);
  const [category, setCategory] = useState(false);
  const [loadingServices, setLoadingServices] = useState(true);
  const [loadingServiceFields, setLoadingServiceFields] = useState(false);
  const [openServicePopUp, setOpenServicePopUp] = useState(false);
  const [openCategoryPopUp, setOpenCategoryPopUp] = useState(false);
  const [openFilterPopUp, setOpenFilterPopUp] = useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [dynamicValues, setDynamicValues] = useState({});
  const [pincode, setPincode] = useState();
  const [zoneId, setZoneId] = useState([]);
  const [subServices, setSubServices] = useState([]);
  const [userAddress, setUserAddress] = useState([]);
  const [favoriteWorkers, setFavoriteWorkers] = useState([]);
  const [openLogin, setOpenLogin] = useState(false);
  const [loading, setLoading] = useState(false);
  const [states, setStates] = useState([]);
  const [addOnService, setAddOnServie] = useState([]);
  const [userCurrentLocation, setUserCurrentLocation] = useState('');
  const [openErrorBar, setOpenErrorBar] = useState(false);
  const [selectedService, setSelectedService] = useState("");
  const [openLocation, setOpenLocation] = useState(false);
  const [addressSelected, setAddressSelected] = useState(null);
  const [languages, setLanguages] = useState([])
  const [booking, setBooking] = useState(null);

  const addressTypes = [
    { id: 5, name: "Home" },
    { id: 3, name: "Office" },
  ];

  const userToken = localStorage.getItem("userToken");

  const handleOpenServicePopup = () => {
    setOpenServicePopUp(true);
    setOpenCategoryPopUp(false);
    setOpenFilterPopUp(false);
  };

  const handleCloseServicePopup = () => {
    setOpenServicePopUp(false);
  };

  const handleOpenCategoryPopUp = () => {
    setOpenCategoryPopUp(true);
    setOpenServicePopUp(false);
    setOpenFilterPopUp(false);
  };

  const handleCloseCategoryPopUp = () => {
    setOpenCategoryPopUp(false);
  };

  const handleOpenFilterPopUp = () => {
    setOpenCategoryPopUp(false);
    setOpenFilterPopUp(true);
  };

  const handleCloseFilterPopUp = () => {
    setOpenFilterPopUp(false);
  };

  const getrefreshToken = async () => {
    try {
      const refreshToken = localStorage.getItem("refreshToken");
      const response = await axios.post(`${apiUrl}users/refresh-token`, {
        accessToken: userToken,
        refreshToken: refreshToken,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': '*/*',
        },
      });

      localStorage.setItem("userToken", response.data.accessToken);
      localStorage.setItem("refreshToken", response.data.refreshToken);
    } catch (error) {
      setIsUserLoggedIn(false);
    }
  };

  const getServices = async () => {
    setLoadingServices(true);
    try {
      const res = await axios.get(`${apiUrl}servicetypes/getservicetypes`);
      setServices(res.data);
    } catch (err) {
      setError(err.response?.data?.message || err.message || "Failed to fetch services.");
      console.error("Error fetching services:", err);
    } finally {
      setLoadingServices(false);
    }
  };

  const getPincodeByLocation = async (latitude, longitude) => {
    try {
      const response = await axios.get(`${apiUrl}zone/getpincode?latitude=${latitude}&longitude=${longitude}`);
      const fetchedPincode = response.data.pin;
      setPincode(fetchedPincode);
    } catch (err) {
      setError(err.response?.data?.message || err.message || "Failed to fetch pincode.");
      console.error("Error fetching pincode:", err);
    }
  };

  const fetchUserLocationAndPincode = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          getPincodeByLocation(latitude, longitude);
        },
        (err) => {
          console.error("Error fetching location:", err);
          setError("Unable to fetch location.");
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  };

  const GetZoneByPinCode = async () => {
    if (!pincode) return;
    try {
      const res = await axios.get(apiUrl + `zone/getzonebypincode?pincode=${pincode}`);
      setZoneId(res.data.zone);
    } catch (error) {
      console.error("Error fetching zone:", error);
    }
  };

  const getServicesFields = async (val) => {
    setLoadingServiceFields(true);
    try {
      localStorage.removeItem("serviceFields");
      const response = await axios.get(`${apiUrl}servicefields/getservicefields`);
      const servicesData = response.data.services;

      if (!servicesData) {
        setServiceFields(null);
        setCategory(false);
        return null;
      }

      const currentService = servicesData.find(
        (service) => parseInt(service.serviceId) === parseInt(val)
      );

      if (currentService) {
        if (!currentService.serviceCategory || currentService.serviceCategory.length === 0) {
          localStorage.setItem("serviceFields", JSON.stringify(currentService));
        }

        setServiceFields(currentService);
        setCategory(currentService.serviceCategory && currentService.serviceCategory.length > 0);
        return currentService;
      } else {
        setServiceFields(null);
        setCategory(false);
        return null;
      }
    } catch (err) {
      setError(err.response?.data?.message || err.message || "Failed to fetch service fields.");
      console.error("Error fetching service fields:", err);
    } finally {
      setLoadingServiceFields(false);
    }
  };

  const getUserDetailsByToken = async () => {
    if (userDetails?.length) return;
    try {
      const response = await axios.get(apiUrl + "users/getuserbytoken", {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      if (response.data.status === false && userToken) {
        getrefreshToken();
      }
      setUserDetails(response.data.userdetails);
      setUserAddress(response.data.addresses);
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  const getFavouriteWorkers = async () => {
    try {
      const response = await axios.get(apiUrl + "users/favoriteworkers", {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      setFavoriteWorkers(response.data.data);
    } catch (error) {
      console.error("Error fetching favorite workers:", error);
    }
  };

  const getSubServicesForZoneId = async (serviceId) => {
    setLoading(true);
    try {
      const res = await axios.get(apiUrl + `servicetypes/0/getservicesubtypesbyserviceidwithzoneid?serviceId=${serviceId}&zoneId=${zoneId.id}`);
      setSubServices(res.data.subservices);
    } catch (error) {
      console.error("Error fetching sub-services:", error);
    } finally {
      setLoading(false);
    }
  };

  const getStates = async () => {
    try {
      const response = await axios.get(apiUrl + "utilities/getstates");
      const filteredStates = response.data.state.filter((val) => val.isActive === true && val.isDeleted === false);
      setStates(filteredStates);
    } catch (error) {
      console.error("Error fetching states:", error);
    }
  };

  const displayStateName = (state) => {
    const stateObj = states.find((val) => val.id == state);
    return stateObj?.stateName;
  };

  const getAddOnService = async (subServiceId) => {
    try {
      const response = await axios.get(apiUrl + `servicetypes/getaddonservices?subServiceId=${subServiceId}`);
      setAddOnServie(response.data);
    } catch (error) {
      console.error("Error fetching add-on services:", error);
    }
  };

  const getLanguages = async () => {
    try {
      const response = await axios.get(apiUrl + "utilities/getlanguages")
      if (response.data.status === true) {
        setLanguages(response.data.languages)
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    if (!services.length) {
      getServices();
    }
  }, [services]);

  useEffect(() => {
    if (userToken) {
      setIsUserLoggedIn(true);
      getUserDetailsByToken();
    }
  }, [userToken]);

  useEffect(() => {
    getStates()
  }, [])

  useEffect(() => {
    if (!pincode) {
      fetchUserLocationAndPincode();
    }
  }, [pincode]);

  useEffect(() => {
    if (!zoneId.length) {
      GetZoneByPinCode();
    }
  }, [pincode]);


  useEffect(() => {
    const userAddress = localStorage.getItem("userAddress");
    if (userAddress) {
      setAddressSelected(JSON.parse(userAddress));
    }
  }, []);


  const contextValue = {
    selectService, selectedAddOn, setSelectedAddOn,
    setSelectService,
    selectedDate,
    favoriteWorkers,
    setSelectedDate,
    category,
    services,
    defaultimg,
    error, userAddress,
    serviceId, selectedService, setSelectedService,
    setServiceId,
    serviceFields,
    getServicesFields,
    loadingServices,
    loadingServiceFields,
    openCategoryPopUp,
    openServicePopUp,
    openFilterPopUp,
    isUserLoggedIn,
    userToken, setIsUserLoggedIn,
    userDetails, loading,booking, setBooking,
    dynamicValues, zoneId, pincode, subServices, setSubServices,
    setDynamicValues, getSubServicesForZoneId,
    handleOpenCategoryPopUp, getUserDetailsByToken,
    handleOpenServicePopup, languages, getLanguages,
    handleCloseCategoryPopUp, addOnService,
    handleCloseServicePopup, addressSelected, setAddressSelected,
    handleOpenFilterPopUp, openLocation, setOpenLocation,
    handleCloseFilterPopUp, openErrorBar, setOpenErrorBar,
    getFavouriteWorkers, states, addressTypes, displayStateName, zoneId,
    openLogin, setOpenLogin, userCurrentLocation, setUserCurrentLocation, getAddOnService
  };

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};

export { Context, UserContext };